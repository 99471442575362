import { template as template_a0382433addb40a885a74dcb81378573 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a0382433addb40a885a74dcb81378573(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
