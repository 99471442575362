import { template as template_ca52bf6ef3b84ab6b2ce234d0570d0bf } from "@ember/template-compiler";
const WelcomeHeader = template_ca52bf6ef3b84ab6b2ce234d0570d0bf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
