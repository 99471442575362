import { template as template_1dc83ebabc4d4f13ba69c85019f7a624 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_1dc83ebabc4d4f13ba69c85019f7a624(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
