import { template as template_34e945b693fe4288bbaf74649d324b81 } from "@ember/template-compiler";
const SidebarSectionMessage = template_34e945b693fe4288bbaf74649d324b81(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
